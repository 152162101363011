import {fetchRecord} from './AxiosAdmin';

// export const adminLogin = (adminDetails) => async dispatch => {
//     let response = await postRecord('auth/admin/login', adminDetails);
//     console.log(response)
//     if(response.status === 200)
//     console.log("OK");
// }

export const isLoggedIn = async () => {
    const response = await fetchRecord('/admin/profile');
    const user = response && response.data && response.data.data;

    let preloadedState = {};
    if (user) {
        preloadedState = {
            authorize: user
        };
    }

    return preloadedState;
};
