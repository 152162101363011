import {applyMiddleware, createStore} from 'redux';
import thunk from "redux-thunk";
import rootReducer from 'src/reducers';

// const loggerMiddleware = createLogger();

export default function configureStore(preloadedState = {}) {
  const store = createStore(rootReducer, preloadedState, applyMiddleware(thunk));
  // store.subscribe(() => {
  //   console.log('From configStore', store.getState());
  // });
  return store;
}
