import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {colors, Typography} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 0,
    flexShrink: 0,
    borderRadius: 2,
    lineHeight: '10px',
    fontSize: '10px',
    height: 20,
    minWidth: 20,
    whiteSpace: 'nowrap',
    padding: theme.spacing(0.5, 1)
  },
  rounded: {
    borderRadius: 50,
    padding: '14px 15px'
  }
}));

function Label({
  className,
  variants,
  color,
  shape,
  children,
  style,
  ...rest
}) {
  const classes = useStyles();
  const rootClassName = clsx(
    {
      [classes.root]: true,
      [classes.rounded]: shape === 'rounded'
    },
    className
  );
  const finalStyle = { ...style };

  if (variants === 'secondary') {
    finalStyle.backgroundColor = '#E8DDD2';
    // finalStyle.backgroundColor = '#EAE1BF';
    finalStyle.color = '#a3784d';
  } else if (variants === 'primary') {
    finalStyle.backgroundColor = '#BFEADE';
    finalStyle.color = '#2e7c6c';
  } else if (variants === 'danger') {
    finalStyle.backgroundColor = '#EABFC8';
    finalStyle.color = '#AA4D61';
  } else if (variants === 'hold') {
    finalStyle.backgroundColor = '#DED0C2';
    finalStyle.color = '#644A30';
  } else if (variants === 'gray') {
    finalStyle.backgroundColor = '#E6E6E6';
    finalStyle.color = '#777777';
  } else {
    //finalStyle.border = `1px solid #BFEADE`;
    //finalStyle.color = '#379B87';
  }

  return (
    <Typography
      {...rest}
      className={rootClassName}
      style={finalStyle}
      variant="overline"
    >
      {children}
    </Typography>
  );
}

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  shape: PropTypes.oneOf(['square', 'rounded']),
  style: PropTypes.object,
  variant: PropTypes.oneOf(['contained', 'outlined'])
};

Label.defaultProps = {
  style: {},
  color: colors.grey[600],
  variant: 'contained',
  shape: 'square'
};

export default Label;
