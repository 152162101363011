import {CLEAR_FLASH, SET_FLASH} from "../actions/Flash";
import {GENERAL} from "../constants/ApiResponseFormat";

let _body = GENERAL;
_body.message = 'Something went wrong.'
export default (state = null, { body, type }) => {
  Object.freeze(state);
  switch (type) {
    case SET_FLASH:
      return body ? body : _body;
    case CLEAR_FLASH:
      return null;
    default:
      return state;
  }
};
