import {combineReducers} from 'redux';
import authorizeReducer from './authorizeReducer';
import notifyReducer from './Notify';
import flashReducer from './Flash';

const rootReducer = combineReducers({
  authorize: authorizeReducer,
  notify:notifyReducer,
  flash:flashReducer
});
export default rootReducer;
