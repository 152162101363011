import axios from 'axios';
import {getAccessToken} from '../services/StorageAdmin';
// import {GENERAL} from "../../../crew/src/constants/ApiResponseFormat";

const token = getAccessToken();
const API_URI = process.env.REACT_APP_API_URL;
const API = axios.create({
  baseURL: API_URI,
  timeout: 20000,
  headers: {'Pragma':'no-cache','Cache-Control':'no-cache, no-store'}
  // headers: { Authorization: token }
});


API.interceptors.request.use(
  config => {
      config.headers.Authorization = getAccessToken()
      return config;
  },
  e => Promise.reject(e)
);

const GENERAL = {
  status: '',
  message: '',
  data: []
};

const postRecord = async (relativeUri, data) => {
  // API.defaults.headers['Authorization'] = getAccessToken();
  try {
    let response = await API.post(relativeUri, data)
      .then(response => {
        GENERAL.status = response.status;
        GENERAL.message = response.data.message;
        GENERAL.data = response.data.data;
        return response;
      })
      .catch(e => {
        GENERAL.status = e.response.status;
        GENERAL.message = e.response.data.message;
        GENERAL.data = e.response.data.data;

        return GENERAL;
      });
    return response;
  } catch (e) {
    //console.log('asdfsdafsaf',e);
    return GENERAL;
  }
};

const updateRecord = async (relativeUri, data) => {
  try {
    // API.defaults.headers['Authorization'] = getAccessToken();

    let response = await API.patch(relativeUri, data)
      .then(response => {
        GENERAL.status = response.status;
        GENERAL.message = response.data.message;
        GENERAL.data = response.data.data;
        return response;
      })
      .catch(e => {
        GENERAL.status = e.response.status;
        GENERAL.message = e.response.data.message;
        GENERAL.data = e.response.data.data;

        return GENERAL;
      });
    return response;
  } catch (e) {
    //console.log('asdfsdafsaf',e);
    return GENERAL;
  }
};

const fetchRecord = async (relativeUri, queryParams) => {
  try {

      if(queryParams) {
        let str = '';
    for (var key in queryParams) {
      if (str != '' && queryParams[key]) {
        str += '&';
      }
      str += queryParams[key] ? key + '=' + encodeURIComponent(queryParams[key]) : '';
    }
          relativeUri = relativeUri + '?' + str;
      }
      let response = await API.get(relativeUri).then(response => {
          GENERAL.status = response.status;
          GENERAL.message = response.data.message;
          GENERAL.data = response.data.data;
          return response;
      }).catch(e => {
        GENERAL.status = e.response.status;
        GENERAL.message = e.response.data.message;
        GENERAL.data = e.response.data.data;

        return GENERAL;
      });

      return response;
  } catch (e) {
      return false;
  }
}

export const fetchRecords = async (relativeUri, pathParam1 = '', pathParam2 = '', queryParams) => {
  let responseContent = {
    status: "",
    message: "",
    data: ""
  };
  try {
    if (pathParam1 !== '') {
      relativeUri = relativeUri + '/' + pathParam1;
    }
    if (pathParam2 !== '') {
      relativeUri = relativeUri + '/' + pathParam2;
    }
    if (queryParams) {
      relativeUri = relativeUri + '?' + queryParams;
    }
    let response = await API.get(relativeUri).then(response => {
      responseContent = {
        status: response.status,
        message: response.data.message,
        data: response.data.data
      };

      return responseContent;
    }).catch(e => {
      return exceptionHandler(e);
    });

    return response;
  } catch (e) {
    return GENERAL;
  }
};

const exceptionHandler = (e) => {
  let responseContent = {};
  if (e.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx

    responseContent = {
      status: e.response.status,
      message: e.response.data.message,
      data: e.response.data.data
    }
    // return responseContent;
    // console.log(e.response.status);
    // console.log(e.response.headers);
  } else if (e.request) {
    // The request was made but no response was received
    // `e.request` is an instance of XMLHttpRequest in the
    // browser and an instance of
    // http.ClientRequest in node.js
    responseContent = {
      status: 503,
      message: "Service Unavailable",
      data: ""
    }
    //return responseContent;
    //console.log(e.request);
  } else {
    //500 Interval Server Error
    // Something happened in setting up the request that triggered an Error
    //console.log('Error', e.message);
    responseContent = {
      status: 500,
      message: e.message,
      data: ""
    }
    // return responseContent;

  }
  return responseContent;
};

const deleteRecord = async (relativeUri, params) => {
  try {
      let response = await API.delete(relativeUri).then(response => {
          GENERAL.status = response.status;
          GENERAL.message = response.data.message;
          GENERAL.data = response.data.data;
          return response;
      }).catch(e => {
        GENERAL.status = e.response.status;
        GENERAL.message = e.response.data.message;
        GENERAL.data = e.response.data.data;

        return GENERAL;
      });

      return response;
  } catch (e) {
      return false;
  }
}

export { API, postRecord, updateRecord, fetchRecord, deleteRecord };
