export const SET_FLASH = "SET_FLASH";
export const CLEAR_FLASH = "CLEAR_FLASH";

export const setFlash = ( body ) => (
    {
      type: SET_FLASH,
      body
    }
);

export const clearFlash = () => ({
  type: CLEAR_FLASH,
});
