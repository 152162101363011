import React from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router';
import {Redirect, withRouter} from 'react-router-dom';

const mapStateToProps = ({ authorize: { id } }) => ({
  loggedIn: Boolean(id)
});


const Auth = ({ loggedIn, path, component: Component }) =>(
  <Route
    path={path}
    render={props =>
      // (loggedIn && Boolean(window.state().authorize.isAddedByAdmin) === true && Boolean(window.state().authorize.isFirstLogin) === true) ? <Redirect to="/my-account-change-password" />
      // : 
      (loggedIn) ? <Redirect to="/dashboard" /> : <Component {...props} />
    }
  />
);

const Protected = ({ loggedIn, path, component: Component }) => (
  <Route
    path={path}
    render={props =>
      loggedIn ? <Component {...props} /> : <Redirect to="/login" />
    }
  />
);

export const AuthRoute = withRouter(connect(mapStateToProps)(Auth));

export const ProtectedRoute = withRouter(connect(mapStateToProps)(Protected));


function renderRoutes(routes, extraProps = {}, switchProps = {}) {
  function checkAuth(route, props, extraProps) {

    if (route.auth && typeof route.auth != 'undefined') {
      //write logic to validate user
      let isAuthorize = Boolean(window.state().authorize._id);
      if(Boolean(window.state().authorize.isAddedByAdmin) === true ){

      }
      if (isAuthorize === false) {
        route.render = (props) => <Redirect to="/auth/login"/>;
        return route.render({...props, ...extraProps, route: route});
      }

    }
    //refresh for current route
    if (route.refresh && typeof route.refresh != 'undefined') {
      if (props.location.pathname == route.path) {

        route.key = Math.random()
          .toString(36)
          .substring(7);
      }
    }

    // if (props.location.pathname.indexOf('auth') > -1 &&  Boolean(window.state().authorize._id) === true) {
    //   // if(Boolean(window.state().authorize.isAddedByAdmin) === true && Boolean(window.state().authorize.isFirstLogin) === true) {
    //   //   console.log("Need to be redirected to change password page.");
    //   //   route.render = props => <Redirect to="/my-account/change-password" />;
    //   // }
    //   // else {
    //   route.render = props => <Redirect to="/dashboard" />;
    //   // }
    //   return route.render({ ...props, ...extraProps, route: route });
    // }
      
    return <route.component {...props} {...extraProps} route={route} />;
  }

  return routes ? (
    <Switch {...switchProps}>
      {routes.map((route, i) => (
        /*  route.auth ?
       
          <ProtectedRoute
            key={route.key || i}
            path={route.path}
            exact={route.exact}
            strict={route.strict}
            render={props =>
            route.render ? (
              route.render({...props, ...extraProps, route: route})
            ) : checkAuth(route, props, extraProps)/!*(

                  <route.component {...props} {...extraProps} route={route} />
                )*!/
          }
        /> :*/

        <Route
          key={route.key || i}
          path={route.path}
          exact={route.exact}
          strict={route.strict}
          render={
            props =>
              route.render
                ? route.render({ ...props, ...extraProps, route: route })
                : checkAuth(
                    route,
                    props,
                    extraProps
                  ) /*(
              
              <route.component {...props} {...extraProps} route={route} />
            )*/
          }
        />
      ))}
    </Switch>
  ) : null;
}

/*export default renderRoutes = withRouter(
  connect(mapStateToProps)(renderRoutes)
);

//export default renderRoutes;// = connect(mapStateToProps)(renderRoutes);*/

export default renderRoutes;
