import {FETCH_USER_DETAILS, LOGOUT} from 'src/actions';

const initialState = {
    firstName: '',
    last_name: '',
    email: '',
    avatar: ''
};

const authorizeReducer = (state = initialState , action) => {
  switch (action.type) {
    case FETCH_USER_DETAILS: {
      return action.userDetails;
      
    }

    case LOGOUT: {
      console.log("LOGOUT")
      return initialState;
    }
    
    default: {
      console.log("Default")
      return state;
    }
  }
};

export default authorizeReducer;
