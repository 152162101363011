/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';

export default [
  {
    items: [
      {
        title: 'Dashboards',
        href: '/dashboard',
        icon: DashboardIcon
      },
      // {
      //   title: 'Management',
      //   href: '/management',
      //   icon: BarChartIcon,
      //   items: [
      //     {
      //       title: 'Customers',
      //       href: '/management/customers'
      //     },
      //     {
      //       title: 'Customer Details',
      //       href: '/management/customers/1/summary'
      //     },
      //     {
      //       title: 'Projects',
      //       href: '/management/projects'
      //     },
      //     {
      //       title: 'Orders',
      //       href: '/management/orders'
      //     },
      //     {
      //       title: 'Order Details',
      //       href: '/management/orders/1'
      //     }
      //   ]
      // },
      // {
      //   title: 'Social Feed',
      //   href: '/social-feed',
      //   icon: PeopleIcon
      // },
      // {
      //   title: 'Profile',
      //   href: '/profile',
      //   icon: PersonIcon,
      //   items: [
      //     {
      //       title: 'Timeline',
      //       href: '/profile/1/timeline'
      //     },
      //     {
      //       title: 'Connections',
      //       href: '/profile/1/connections'
      //     },
      //     {
      //       title: 'Projects',
      //       href: '/profile/1/projects'
      //     },
      //     {
      //       title: 'Reviews',
      //       href: '/profile/1/reviews'
      //     }
      //   ]
      // },
      // {
      //   title: 'Project',
      //   href: '/projects',
      //   icon: FolderIcon,
      //   items: [
      //     {
      //       title: 'Browse',
      //       href: '/projects'
      //     },
      //     {
      //       title: 'Create',
      //       href: '/projects/create'
      //     },
      //     {
      //       title: 'Overview',
      //       href: '/projects/1/overview'
      //     },
      //     {
      //       title: 'Files',
      //       href: '/projects/1/files'
      //     },
      //     {
      //       title: 'Activity',
      //       href: '/projects/1/activity'
      //     },
      //     {
      //       title: 'Subscribers',
      //       href: '/projects/1/subscribers'
      //     }
      //   ]
      // },
      // {
      //   title: 'Invoice',
      //   href: '/invoices/1',
      //   icon: ReceiptIcon
      // },
      // {
      //   title: 'Kanban Board',
      //   href: '/kanban-board',
      //   icon: ListAltIcon
      // },
      // {
      //   title: 'Mail',
      //   href: '/mail',
      //   icon: MailIcon,
      //   label: () => (
      //     <Label
      //       color={colors.red[500]}
      //       shape="rounded"
      //     >
      //       2
      //     </Label>
      //   )
      // },
      // {
      //   title: 'Chat',
      //   href: '/chat',
      //   icon: ChatIcon,
      //   label: () => (
      //     <Label
      //       color={colors.red[500]}
      //       shape="rounded"
      //     >
      //       4
      //     </Label>
      //   )
      // },
      // {
      //   title: 'Calendar',
      //   href: '/calendar',
      //   icon: CalendarTodayIcon,
      //   label: () => <Label color={colors.green[500]}>New</Label>
      // },
      {
        title: 'User List',
        href: '/users',
        icon: PeopleIcon
      },
      {
        title: 'Admin List',
        href: '/admin-list',
        icon: PeopleIcon
      },
      {
        title: 'Production List',
        href: '/productions',
        icon: PeopleIcon
      },
      //  {
      //    title: 'Skills',
      //    href: '/skills',
      //    icon: FolderIcon
      //  },
      {
        title: 'Project',
        href: '/projects',
        icon: FolderIcon,
        items: [
          {
            title: 'List Projects',
            href: '/projects',
          },
          {
            title: 'Create Project',
            href: '/projects/create',
          },
          {
            title: 'Categories',
            href: '/project/categories',
          },
          {
            title: 'Member Roles',
            href: '/project/member-roles',
          },
        ]
      },
      {
        title: 'Services',
        href: '/services/category',
        icon: FolderIcon,
        items: [
          {
            title: 'Category',
            href: '/services/category',
          },
          {
            title: 'List',
            href: '/services/list'
          },
        ]
      },
      {
        title: 'Company Types',
        href: '/company-types/category',
        icon: FolderIcon,
        items: [
          {
            title: 'Category',
            href: '/company-types/category',
          },
          {
            title: 'List',
            href: '/company-types/list'
          },
        ]
      },
    //   {
    //     title: 'Professions',
    //     href: '/professions/category',
    //     icon: FolderIcon,
    //     items: [
    //       {
    //         title: 'Category',
    //         href: '/professions/category',
    //       },
    //       {
    //         title: 'List',
    //         href: '/professions/list'
    //       },
    //     ]
    //   },
      {
        title: 'Subscription',
        href: '/subscription/plans',
        icon: FolderIcon,
        items: [
          {
            title: 'Plans',
            href: '/subscription/plans',
          },
          {
            title: 'Subscriptions',
            href: '/subscription/list'
          },
          {
            title: 'Invoices',
            href: '/subscription/invoices'
          },
        ]
      },
      {
        title: 'Pages',
        href: '/pages/terms-and-conditions',
        icon: FolderIcon,
        items: [
          {
            title: 'Crewing Search Start',
            href: '/crewing-search'
          },
          {
            title: 'Companies Search Start',
            href: '/company-search'
          },
          {
            title: 'Project Search Start',
            href: '/project-search'
          },
          {
            title: 'Terms And Condition',
            href: '/pages/terms-and-conditions'
          },
          {
            title: 'Privacy Policy',
            href: '/pages/privacy-policy'
          },
          {
            title: 'About Us',
            href: '/pages/about-us'
          },
          {
            title: 'Contact Us',
            href: '/pages/contact-us'
          },
          {
            title: 'Booking Agents',
            href: '/booking-agents'
          }
        ]
      },
      {
        title: 'Banners',
        href: '/banner/crewing',
        icon: FolderIcon,
        items: [
          {
            title: 'Crewing',
            href: '/banner/crewing'
          },
          {
            title: 'Projects',
            href: '/banner/projects'
          },
          {
            title: 'Companies',
            href: '/banner/companies'
          },
        ]
      },
      {
        title: 'My Account',
        href: '/my-account',
        icon: PersonIcon,
        items: [
          {
            title: 'General',
            href: '/my-account/general'
          },
          {
            title: 'Change Password',
            href: '/my-account/change-password'
          }
        ]
      },
      {
        title: 'Settings',
        href: '/settings',
        icon: SettingsIcon
      }
    ]
  }
];
