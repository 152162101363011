export const FETCH_USER_DETAILS = 'FETCH_USER_DETAILS';
export const LOGOUT = 'LOGOUT';

export const fetchUserDetails = userDetails => 
  ({
    type: FETCH_USER_DETAILS,
    userDetails
  });

export const logout = () => ({
  type: LOGOUT
});
