import React, {useEffect, useState} from 'react';
import {Avatar, CardMedia} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import getInitials from 'src/utils/getInitials';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  media: {
    height: 160,
    backgroundColor: '#505050',
    backgroundBlendMode: 'overlay'
  },
  mediaCompany: {
    height: 240,
    '&.is-connection': {
      height: 188,
      position: 'relative'
    }
  },
  brandlogo: {
    width: 100,
    height: 100,
    position: 'absolute',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    zIndex: 2,
    '&.is-connection': {
      width: 88,
      height: 88,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6)
  },
}));

export default function Thumbnail(props) {
  const classes = useStyles();
  const [thumb, setThumb] = useState(null);

  useEffect(() => {
    if (props.imageUri) {

      let key = 'crews-prod-items-thumb-' + props.size;
      setThumb(props.imageUri.replace('crews-prod-items', key));
    }


  }, [props.imageUri]);

  return (
    <>
      {props.size === 'small' &&
      <Avatar
        // alt={props.title?props.title:''}
        alt={props.title ? getInitials(props.title) : ''}
        src={
          thumb
        }
        onClick={props.onClick}
        className={props.className}
        // altInitial={getInitials(props.title)}
      > {getInitials(props.title)}
      </Avatar>
      }
      {props.size === 'medium' &&
      <CardMedia
        onClick={props.onClick}
        image={thumb}
        title={props.title}
        className={props.type === 'company' ? clsx(classes.mediaCompany, 'has-media-overlay') : clsx(classes.media, 'has-media-overlay')}
      />
      }
    </>
  );
}
